export const ENDPOINT = "https://api-ristoratori.mycia.it/v2/restaurants/?id=:id&lang=:lang";
export const ROOTENDPOINT = "https://api-ristoratori-v3.mycia.it/arzignano/";
export const DISHESENDPOINT = "https://api-ristoratori-v3.mycia.it/foods/dishes/:id";
export const TOKENENDPOINT = "https://api-ristoratori.mycia.it/v2/restaurants/?token=:token&lang=:lang";
export const GMAPENDPOINT = "https://api-ristoratori.mycia.it/googleapis/maps/api/distancematrix/json/?origins=:oLat,:oLng&destinations=:dLat,:dLng&mode=driving";
export const COURSESENDPOINT = "https://api-ristoratori.mycia.it/v2/courses/?id=:id&lang=:lang"
export const RESTAURANTSENDPOINT = "https://api-ristoratori-v3.mycia.it/restaurants?network=:network&city=:city&pacchetti=:pacchetti"
export const RESTAURANTSENDPOINTSTAGING = "https://api-staging-ristoratori-v3.mycia.it/restaurants?network=:network&city=:city&pacchetti=:pacchetti"
export const SEARCHENDPOINT = "https://api-ristoratori-v3.mycia.it/restaurants?network=:network&name=:name";
export const SEARCHFOODENDPOINT = "https://app.mycia.it/api/v2/restaurants/search/plate?food_type=:food_type&network=:network";
export const SEARCHWINEENDPOINT = "https://app.mycia.it/api/v2/restaurants/search/wine?wine=:wine&network=:network";
export const NEWSLETTERENDPOINT = "https://api-ristoratori-v3.mycia.it/newsletter/subscriptions";
export const ANALYTICSENDPOINT = "https://logger.mycia.it/v2/logs/webapp";
